import Thermon from "../../images/Portfolio_Pics/Thermon_portfolio.png";
import WBSD from "../../images/Portfolio_Pics/WBSD_portfolio.png";
import Lum from "../../images/Portfolio_Pics/Lum_portfolio.png";
import Devsavages from "../../images/Portfolio_Pics/DevSavages_portfolio.png";
import Louflix from "../../images/Portfolio_Pics/Louflix_porfolio.png";
import VirtualReality from "../../images/Portfolio_Pics/Vr_virtual_reality_porfolio.png";
import Loud from "../../images/Portfolio_Pics/Loud_porfolio.png";
import Caculator from "../../images/Portfolio_Pics/Calculator_porfolio.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEarthAmerica,
  faMicrochip,
  faLaptop,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {
  faSquareFacebook,
  faSquareGithub,
  faSquareInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

export let colors = ["rgb(19, 225, 252)", "rgb(145, 145, 145)"];

export let singlePage = false;

export const info = {
  firstName: "Lou",
  lastName: "Carter",
  initials: "js",
  position: "A Full Stack Developer",
  gradient: `-webkit-linear-gradient(135deg, ${colors})`,
  baseColor: colors[0],
  miniBio: [
    {
      emoji: <FontAwesomeIcon icon={faMicrochip} className="emoji-icon" />,
      text: "Fueled by Artificial Intelligence ",
    },
    {
      emoji: <FontAwesomeIcon icon={faEarthAmerica} className="emoji-icon" />,
      text: "Brooklyn Born, Bronx Raised, USA",
    },
    {
      emoji: <FontAwesomeIcon icon={faLaptop} className="emoji-icon" />,
      text: "React.js, PHP, Wordpress Maniac",
    },
    {
      emoji: <FontAwesomeIcon icon={faEnvelope} className="emoji-icon"/>,
      text: "louiscarterjr29@gmail.com",
    },
  ],
  socials: [
    {
      link: "https://www.facebook.com/Lou.Carter.Jr",
      icon: <FontAwesomeIcon icon={faSquareFacebook} size="2x" className="aboutm-sm-icon" />,
      label: "facebook",
    },
    {
      link: "https://www.instagram.com/luis_carter_castro_jr/",
      icon: <FontAwesomeIcon icon={faSquareInstagram} size="2x" className="aboutm-sm-icon" />,
      label: "instagram",
    },
    {
      link: "https://github.com/Babyhacker36",
      icon: <FontAwesomeIcon icon={faSquareGithub} size="2x"  className="aboutm-sm-icon"/>,
      label: "github",
    },
    {
      link: "https://www.linkedin.com/in/louiscarterjr",
      icon: <FontAwesomeIcon icon={faLinkedin} size="2x"  className="aboutm-sm-icon"v/>,
      label: "linkedin",
    },
  ],
  bio: '"I\'m a Full-Stack Developer and Air Force veteran with 8+ years of experience, bringing the perfect mix of front-end and back-end wizardry. I build sleek, responsive apps, master industry coding standards, and know my way around web marketing and project management.  \n\n   Like a true Mandalorian, I live by the code of problem-solving taking complex ideas and forging them into seamless user experiences. This is the way!⚡ \n\n  To the developers working on AI—just remember, it’s only a matter of time before the robots figure out how to program better than us. Until then, let’s keep pretending that the code works and that we totally understand the deep neural networks we’re building. Happy coding, and remember: when the aliens come, let’s hope they bring documentation."',

  portfolio: [
    {
      title: "DevSavages.ai",
      live: "https://devsavages.ai",
      source: null,
      image: Devsavages,
    },
    {
      title: "Thermon Inc",
      live: "https://thermon.com",
      source: "",
      image: Thermon,
    },
    {
      title: "Luminare Health ",
      live: "https://www.luminarehealth.com/",
      source: "",
      image: Lum,
    },
    {
      title: "WB Southern Drilling",
      live: "https://wbsoutherndrillingtx.com",
      source: "",
      image: WBSD,
    },
    {
      title: "LouFlix API",
      live: "https://louiscarterjr.com/louflix",
      source: "https://github.com/Babyhacker36/LouFlix",
      image: Louflix,
    },
  

    {
      title: "VR Showcase",
      live: "https://louiscarterjr.com/virtualreality/",
      source: "https://github.com/Babyhacker36/VRVirtualReality",
      image: VirtualReality,
    },
    {
      title: "Loud (gif api)",
      live: "https://louiscarterjr.com/loud/",
      source: "https://github.com/Babyhacker36/loud",
      image: Loud,
    },

    {
      title: "Calculator USD/CA",
      live: "https://thermon.com/resources_ext/led_cal_usa/",
      source: "https://github.com/Babyhacker36/Thermon-Calculator-",
      image: Caculator,
    },
  ],
};
