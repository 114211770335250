import React from "react";
import { Row, Col } from "react-bootstrap";

function EmojiBullet(props) {
  const { emoji, text } = props;

  return (
    <Row>
      <Col>
        <div className="emojidiv">
          <div style={{ width: "auto", marginRight: "25px" }}>{emoji}</div>
          <div>{text}</div>

          
        </div>
      </Col>
    </Row>
  );
}

export default EmojiBullet;
